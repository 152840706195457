import React from "react";
import Content from '../views/UseCases';

export default ({location}) => (
  <Content location={location} seo={{
    title: "Innovative og digitale køretøjsløsninger",
    lang: 'da-DK',
    description: "Leder du efter fjernovervågning af køretøjer eller forebyggende vedligeholdelse af køretøjer? Find den nye teknologiske løsning til bilindustrien",
    meta: [{
      name: 'keywords',
      content: 'Digitale køretøjsløsninger',
    }]
  }}/>
);